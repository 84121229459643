<template>
  <v-container id="driverdashboard" fluid tag="section">
    <alert-notification :message="alert" />
    <v-row class="text-center">
      <v-col cols="12" class="text-h1">
        {{ period }}
      </v-col>
      <v-col cols="12" class="text-h3">
        <mark class="light-blue pa-1">{{ $tc('driverDashboard.text.orders', countOrdersNow, {count: countOrdersNow}) }}</mark>
      </v-col>
      <v-col v-if="countOrdersNow > 0 && countOrdersNow === countOrdersTargetNow" cols="12" class="text-h3">
        <mark class="red pa-1">{{ $t('driverDashboard.text.yourAreBestDriver') }}</mark>
      </v-col>
      <v-col v-else-if="countOrdersTargetNow > 0" cols="12" class="text-h3">
        {{ $tc('driverDashboard.text.bestDriver') }} <mark class="red pa-1">{{ $tc('driverDashboard.text.orders', countOrdersTargetNow, {count: countOrdersTargetNow}) }}</mark>
      </v-col>
      <v-col cols="12" class="text-h1">
        {{ $t('driverDashboard.text.period.week') }}
      </v-col>
      <v-col cols="12" class="text-h3">
        <mark class="light-blue pa-1">{{ $tc('driverDashboard.text.orders', countOrdersThisWeek, {count: countOrdersThisWeek}) }}</mark>
        {{ $tc('driverDashboard.text.deliveryIn', countOrdersThisWeek, {count: countOrdersThisWeek}) }}
        <mark class="light-green pa-1">{{ $tc('driverDashboard.text.services', countServicesThisWeek, {count: countServicesThisWeek}) }}</mark>
      </v-col>
      <v-col cols="12" class="text-h1">
        {{ $t('driverDashboard.text.period.previousWeek') }}
      </v-col>
      <v-col cols="12" class="text-h3">
        <mark class="light-blue pa-1">{{ $tc('driverDashboard.text.orders', countOrdersPreviousWeek, {count: countOrdersPreviousWeek}) }}</mark>
        {{ $tc('driverDashboard.text.deliveryIn', countOrdersPreviousWeek, {count: countOrdersPreviousWeek}) }}
        <mark class="light-green pa-1">{{ $tc('driverDashboard.text.services', countServicesPreviousWeek, {count: countServicesPreviousWeek}) }}</mark>
      </v-col>
      <v-col cols="12" class="text-h1">
        {{ $t('driverDashboard.text.period.month') }}
      </v-col>
      <v-col cols="12" class="text-h3">
        <mark class="light-blue pa-1">{{ $tc('driverDashboard.text.orders', countOrdersThisMonth, {count: countOrdersThisMonth}) }}</mark>
        {{ $tc('driverDashboard.text.deliveryIn', countOrdersThisMonth, {count: countOrdersThisMonth}) }}
        <mark class="light-green pa-1">{{ $tc('driverDashboard.text.services', countServicesThisMonth, {count: countServicesThisMonth}) }}</mark>
      </v-col>
      <v-col cols="12" class="text-h1">
        {{ $t('driverDashboard.text.period.previousMonth') }}
      </v-col>
      <v-col cols="12" class="text-h3">
        <mark class="light-blue pa-1">{{ $tc('driverDashboard.text.orders', countOrdersPreviousMonth, {count: countOrdersPreviousMonth}) }}</mark>
        {{ $tc('driverDashboard.text.deliveryIn', countOrdersPreviousMonth, {count: countOrdersPreviousMonth}) }}
        <mark class="light-green pa-1">{{ $tc('driverDashboard.text.services', countServicesPreviousMonth, {count: countServicesPreviousMonth}) }}</mark>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';

  export default {
    name: 'DriverDashboard',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        stat: {}
      };
    },
    computed: {
      period () {
        if (this.stat?.now?.period === undefined) {
          return this.$i18n.t('driverDashboard.text.period.evening');
        }

        return this.$i18n.t('driverDashboard.text.period.' + this.stat.now.period);
      },
      countOrdersNow () {
        if (this.stat?.now?.order?.count === undefined) {
          return 0;
        }

        return this.stat.now.order.count;
      },
      countOrdersTargetNow () {
        if (this.stat?.now?.order?.target === undefined) {
          return 0;
        }

        return this.stat.now.order.target;
      },
      countOrdersThisWeek () {
        if (this.stat?.week?.order?.count === undefined) {
          return 0;
        }

        return this.stat.week.order.count;
      },
      countServicesThisWeek () {
        if (this.stat?.week?.service?.count === undefined) {
          return 0;
        }

        return this.stat.week.service.count;
      },
      countOrdersPreviousWeek () {
        if (this.stat?.previousWeek?.order?.count === undefined) {
          return 0;
        }

        return this.stat.previousWeek.order.count;
      },
      countServicesPreviousWeek () {
        if (this.stat?.previousWeek?.service?.count === undefined) {
          return 0;
        }

        return this.stat.previousWeek.service.count;
      },
      countOrdersThisMonth () {
        if (this.stat?.month?.order?.count === undefined) {
          return 0;
        }

        return this.stat.month.order.count;
      },
      countServicesThisMonth () {
        if (this.stat?.month?.service?.count === undefined) {
          return 0;
        }

        return this.stat.month.service.count;
      },
      countOrdersPreviousMonth () {
        if (this.stat?.previousMonth?.order?.count === undefined) {
          return 0;
        }

        return this.stat.previousMonth.order.count;
      },
      countServicesPreviousMonth () {
        if (this.stat?.previousMonth?.service?.count === undefined) {
          return 0;
        }

        return this.stat.previousMonth.service.count;
      }
    },
    mounted () {
      this.refresh();
    },
    destroyed () {
    },
    methods: {
      async refresh () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/driver', { timeout: 60000 });
          if (response.data !== undefined && response.data.driver !== undefined) {
            this.stat = response.data.driver;
            this.error = '';
          }
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('driverDashboard.error'));
        }
      }
    }
  };
</script>
